<template>
  <td
    :class="[
      'text-body-1',
      disabled ? 'disabled_item--text' : 'primary--text clickable',
    ]"
    :style="{ textAlign: header.align ? header.align : 'center' }"
    @click.stop="handleClick"
  >
    <u>
      <slot>{{ value }}</slot>
    </u>
  </td>
</template>

<script>
export default {
  props: {
    header: {},
    item: {},
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit("clicked", this.header, this.item);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
</style>